<template>
  <v-row
    v-if="!availableFractions || availableFractions.length === 0"
    :class="{ 'fraction-options': true, desktop: !$vuetify.display.mobile }"
    dense>
    <v-col class="mb-1">
      <v-skeleton-loader v-for="index in 3" :key="index" type="avatar, article" min-height="124" />
    </v-col>
  </v-row>

  <v-row v-else :class="{ 'fraction-options': true, desktop: !$vuetify.display.mobile }" dense>
    <v-col
      v-for="availableFraction in availableFractions"
      :key="availableFraction.id"
      cols="12"
      class="mb-1">
      <VCard
        rounded="small"
        min-height="50px"
        :class="{
          'align-content-center mx-auto no-shadow border-theme': true,
          'active-border': pickedFractionId === availableFraction.id,
        }"
        hover
        @click="selectFraction(availableFraction.id)">
        <v-row dense class="px-0" justify="center">
          <v-col cols="4" class="text-center py-6 pl-0 align-content-center">
            <div class="py-0">
              <div class="pt-3">
                <NuxtImg
                  v-if="availableFraction.image !== null && availableFraction.image !== ''"
                  provider="directus"
                  :src="availableFraction.image"
                  :modifiers="{ format: 'auto', height: 72 }"
                  :alt="availableFraction.image_alt_text || availableFraction.description"
                  height="72" />

                <NuxtImg
                  v-else
                  provider="directus"
                  src="de75545d-5e6b-4d53-9feb-06ff9d6d8567"
                  :modifiers="{ format: 'auto', height: 72 }"
                  :alt="availableFraction.image_alt_text || availableFraction.description"
                  height="72" />
              </div>
            </div>
          </v-col>

          <v-col cols="8" class="py-3 pr-4">
            <span class="font-weight-bold">
              {{ availableFraction.description }}
            </span>

            <p class="pt-1 text-caption text-md-body-2">
              {{ t('for_example') }}&nbsp;{{
                availableFraction.allowed_items && availableFraction.allowed_items.length > 5
                  ? availableFraction.allowed_items.slice(0, 5).join(', ') + '...'
                  : availableFraction.allowed_items?.join(', ')
              }}
            </p>
          </v-col>
        </v-row>
      </VCard>
    </v-col>
  </v-row>

  <v-row dense>
    <v-col cols="12">
      <ButtonPrimary class="mt-4" width="100%" min-height="80px" @click="confirmFractionChange">
        {{ t('carry_over') }}
      </ButtonPrimary>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
const { t } = useI18n()
const sidebarStore = useSidebarStore()
const offerStore = useOfferStore()
const { currentFractionId, availableFractionsForCity } = storeToRefs(offerStore)

const fractionStore = useFractionStore()
fractionStore.getFractionData()
const { fractions } = storeToRefs(fractionStore)

const availableFractions = computed(() => {
  return fractions.value?.filter(fraction =>
    availableFractionsForCity.value.includes(fraction.empto_id),
  )
})

const pickedFractionId = ref<number>(currentFractionId.value ?? 0)

const selectFraction = (fractionId: number) => {
  pickedFractionId.value = fractionId
}

const confirmFractionChange = async () => {
  sidebarStore.closeSidebar()

  if (pickedFractionId.value === currentFractionId.value) return

  const pickedFraction = availableFractions.value?.find(
    fraction => fraction.id === pickedFractionId.value,
  )

  if (!pickedFraction) return

  offerStore.setCurrentFractionId(pickedFractionId.value)

  await navigateTo({ path: `/${pickedFraction.shop_slug}` })
}
</script>

<style scoped lang="scss">
@media (max-width: 959px) {
  .text-caption.smaller {
    font-size: 11px !important;
  }
}

@media (max-width: 400px) {
  .text-caption {
    font-size: 10px !important;
  }
  .text-caption.smaller {
    font-size: 9px !important;
  }
}

.fraction-options {
  max-height: 420px;
  overflow-y: auto;
  overflow-x: visible;
}

.fraction-options.desktop {
  max-height: 65vh;
}

@media (max-height: 850px) {
  .fraction-options.desktop {
    max-height: 450px;
  }
}

@media (max-height: 949px) {
  .fraction-options.desktop {
    max-height: 520px;
  }
}

@media (max-height: 1199px) {
  .fraction-options.desktop {
    max-height: 58vh;
  }
}
</style>
